import React, {useState, useEffect} from "react";
import Nav from "../Navbar.js/Nav";
import blogimg from "../assests/blogimg.jpeg";
import Cta from "../Include/Cta";
import Footer from "../Include/Footer";
import {Link} from "react-router-dom";
import caseimg from '../assests/3.png'
import caseimg2 from '../assests/4.png'

function CaseStudies() {

  const dynamicData = [
    {
      id: 1,
      src: caseimg,
      description: "Starry Secrets: A Gen-Z Star-Studded chat show!",
      desc:"Keen to know what goes on and off-screen in the life of your favourite star? Watch the Show!"
    },
    {
      id: 2,
      src: caseimg2,
      description: "Lionesses Roar in Kabaddi: Unleashing Fierce Spirit",
      desc:"Wanna know what goes behind that it takes a village? Walk through this written documentary.  "

    }
    
    // Add more items as needed
  ];

  const [teamData, setTeamData] = useState();
  //('====================================');
  //(teamData);
  //('====================================');
  
  useEffect(() => {
    const fetchTeamData = async () => {
      try {
        const response = await fetch(
          "https://wondar-dev.katsamsoft.com/api/resource/KM%20Blog?fields=[%22*%22]"
        );
        const data = await response.json();
         
        setTeamData(data.data);

      } catch (error) {
        console.error("Error fetching team data:", error);
      }
    };

    fetchTeamData();
  }, []);
  return (
    <>
      <section className="hero-section">
        <div className="banner-hero-about">
          <Nav />
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-8">
              <div className='aboutdivcontent'>
                <h2>
                  {" "}
                  Bharat's Own <br></br>Full Service Agency{" "}
                </h2>
                <p>
                  We help founders and entrepreneurs{" "}
                  <b>feel the change in business</b> <br></br>by providing
                  creative marketing services that drive results.{" "}
                </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>





<section  className='bloggrid' >
    <div className='container'>
        <div className='row'>
         

        {dynamicData.map((item) => (
                <>
            <div className='col-md-6 col-12'>
                <div className='imgblog'>
                    <img src={item.src}  className='img-fluid'/>
            <div className='blogtitle'>
                <p> {item.description}</p>
                <span className='date'>22 April, 2024</span>&nbsp;&nbsp;&nbsp;

            </div>
            <div className='blogdivider'></div>
            <div className='readmorearea'>
              <div className='row'>
                <div className='col-md-1 col-2'>
<svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="17.5" cy="17.5" r="17" stroke="black"/>
<path d="M8 16.5089L23.7534 16.5089L18.0271 13" stroke="black" stroke-width="1.5"/>
<path d="M8 19.5L26.3129 19.5" stroke="black"/>
</svg>
</div>
                <div className='col-4 align-self-center'><Link to="/casestudiesdetails"><span  className='readmore'>Read More</span></Link></div>

              </div>
            </div>
                </div>
            </div>

            </>
              ))}
        </div>

       
    </div>
</section>

      
      <Cta />
      <Footer />
    </>
  );
}

export default CaseStudies;
