import React from 'react'
import Nav from '../Navbar.js/Nav'
import arrownew from "../assests/arrownew.png";
import Cta from '../Include/Cta';
import Footer from '../Include/Footer';

const Privacy = () => {
  return (
    <>
    <section className="hero-section">
        <div className="banner-hero-about">
          <Nav />
          <div className="container">
            <div className="row">
            
              <div className="col-md-7 col-sm-8">
               <div className='aboutdivcontent'>
                <h2> Privacy Policy </h2>
                <p>We help founders and entrepreneurs <b>feel the change in business. </b></p>
                <img src={arrownew} className="img-fluid" />
                </div>
              </div>
             
            </div>
          </div>
        </div>
    </section>
    <div className='container-fluid'>
        <div className='row privacy1'>
            <div className='col-md-10 col-12'>
                <h4>What is Full Service Agency</h4>
                
                <p>We help founders and entrepreneurs feel the change in business by providing creative marketing services that drive results. Find more about us here!</p>
               
                <p>We help founders and entrepreneurs feel the change in business by providing creative marketing services that drive results. Find more about us here!</p>
            </div>

            <div className='col-md-10 col-12'>
                <h4>What is Full Service Agency</h4>
                
                <p>We help founders and entrepreneurs feel the change in business by providing creative marketing services that drive results. Find more about us here!</p>
               
                <p>We help founders and entrepreneurs feel the change in business by providing creative marketing services that drive results. Find more about us here!</p>
            </div>

            <div className='col-md-10 col-12'>
                <h4>What is Full Service Agency</h4>
                
                <p>We help founders and entrepreneurs feel the change in business by providing creative marketing services that drive results. Find more about us here!</p>
               
                <p>We help founders and entrepreneurs feel the change in business by providing creative marketing services that drive results. Find more about us here!</p>
            </div>
           
        </div>
    </div>
    <Cta/>
    <Footer />
    </>
  )
}

export default Privacy