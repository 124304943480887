import React, { useState, useEffect } from "react";
import {Link} from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Helmet } from 'react-helmet';
const Media = () => {
  const [teamData, setTeamData] = useState([]);
  //(teamData)
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const fetchTeamData = async () => {
      try {
        const response = await fetch(
          "https://wondar-dev.katsamsoft.com/api/resource/Katsam%20Media%20Team?fields=[%22*%22]&limit_page_length=100"
        );
        const data = await response.json();

        const sortedData = data.data.sort((a, b) => a.custom_position - b.custom_position);
        //(sortedData);
        setTeamData(sortedData);
      } catch (error) {
        console.error("Error fetching team data:", error);
      }
    };

    fetchTeamData();
  }, []);

  const handleImageLoad = () => {
    setLoaded(true);
  };

  const options = {
    items: 5,
    loop: true,
    nav: false,
    dots: false,
    margin: 25,
    autoplay: true,
    autoplayTimeout: 3000,
    autoplayHoverPause: true,
    responsive: {
      0: {
        items: 1.2,
      },
      600: {
        items: 1.2,
      },
      1000: {
        items: 4.5,
      },
      1200: {
        items: 4.5,
      },
    },
  };

  return (
    <>

<Helmet>                   
<title> Full Service Marketing Agency | Social Media | PPC | Influencer - India</title>
<meta name="description" content="- Discover how our full-service branding agency can transform your brand's identity and visibility. Let's boost your brand together." />
      </Helmet>
    <section>
      <div className="menu d-none d-sm-none d-md-block">
      <div className="menu__item">
           <div className="marquee">
             <div className="marquee__inner">
               <span className="d-block d-sm-none d-md-block">Katsam MEDIA</span>
               <span className="d-block d-sm-none d-md-block">Katsam MEDIA</span>
               <span className="d-block d-sm-none d-md-block">Katsam MEDIA</span>
               <span>Katsam MEDIA</span>
             </div>
           </div>
         </div>
      </div>

      <div className="container-fluid">
      <div className="headcontainer">
           <div className="red-circle"></div>
          <h5 className="headtitle">Katsam People
             <div className="strip"></div>
           </h5>
         </div>

        <div className="d-block d-sm-block d-md-none">
          <OwlCarousel className="owl-theme" {...options}>
          {teamData.map((item) => (
              <div className="col-md-3 col-xl-3 col-lg-4" key={item.id}>
                <div className="card position-relative">
                    <img
                      src={"https://wondar-dev.katsamsoft.com/"+item.custom_profile_image}
                      className="card-img-top"
                      alt={item.name}
                    />
                  <div className="card-details">
                    <h5>{item.custom_name}</h5>
                    <div className="stripteam"></div>
                    <p className="p1">{item.custom_designation}</p>
                    <div className="para">
                      <p>{item.custom_bio_description}</p>
                      <div className="social-logo d-flex gap-3">
                      <Link to="https://github.com/mahaveer82"><i class="fa fa-linkedin-square" aria-hidden="true"></i></Link>
                      <Link to={item.custom_linkedin_url}> <i class="fa fa-instagram" aria-hidden="true">{item.custom_instagram_url}</i></Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </OwlCarousel>
        </div>

        <div className="d-none d-sm-none d-md-block">
          <div className="row">
            {teamData.map((item) => (
              <div className="col-md-3 col-xl-3 col-lg-4" key={item.id}>
                <div className="card position-relative">
                    <img
                      src={"https://wondar-dev.katsamsoft.com/"+item.custom_profile_image}
                      className="card-img-top"
                      alt={item.name}
                    />
                  <div className="card-details">
                    <h5>{item.custom_name}</h5>
                    <div className="stripteam"></div>
                    <p className="p1">{item.custom_designation}</p>
                    <div className="para">
                      <p>{item.custom_bio_description}</p>
                      <div className="social-logo d-flex gap-3">
                      <Link to="https://github.com/mahaveer82"><i class="fa fa-linkedin-square" aria-hidden="true"></i></Link>
                      <Link to={item.custom_linkedin_url}> <i class="fa fa-instagram" aria-hidden="true">{item.custom_instagram_url}</i></Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>


    </>

  );
};

export default Media;
