import React, {useState, useEffect} from "react";
import Nav from "../Navbar.js/Nav";
import blogimg from "../assests/blogimg.jpeg";
import Cta from "../Include/Cta";
import Footer from "../Include/Footer";
import {Link} from "react-router-dom";
import caseimg from '../assests/3.png'
import caseimg2 from '../assests/4.png'

function CaseStudiesdeatils() {



  const [teamData, setTeamData] = useState();
  //('====================================');
  //(teamData);
  //('====================================');
  
  useEffect(() => {
    const fetchTeamData = async () => {
      try {
        const response = await fetch(
          "https://wondar-dev.katsamsoft.com/api/resource/KM%20Blog?fields=[%22*%22]"
        );
        const data = await response.json();
         
        setTeamData(data.data);

      } catch (error) {
        console.error("Error fetching team data:", error);
      }
    };

    fetchTeamData();
  }, []);
  return (
    <>
      <section className="hero-section">
        <div className="banner-hero-about">
          <Nav />
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-8">
              <div className='aboutdivcontent'>
                <h2>
                  {" "}
                  Bharat's Own <br></br>Full Service Agency{" "}
                </h2>
                <p>
                  We help founders and entrepreneurs{" "}
                  <b>feel the change in business</b> <br></br>by providing
                  creative marketing services that drive results.{" "}
                </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>


<div className="comingsoon">
  <h4>Coming Soon ...</h4>
</div>


      
      <Cta />
      <Footer />
    </>
  );
}

export default CaseStudiesdeatils;
