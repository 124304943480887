import React, { useState, useEffect } from "react";
import Nav from "../Navbar.js/Nav";
import { IoPlay } from "react-icons/io5";
import Cta from "../Include/Cta";
import Footer from "../Include/Footer";
import about from "../assests/about.png";
import TypeIt from "typeit-react";
import km from ".././founder.jpg";
import { useLocation } from "react-router-dom";
import video from '../assests/katsam.mp4';
import "../App.css";
import { Helmet } from 'react-helmet';


export default function About() {
  const [isTransitioning, setTransitioning] = useState(true);

  useEffect(() => {
    // Set a timeout to update the transition after a delay
    const timeout = setTimeout(() => {
      setTransitioning(false); // Set transitioning to false after the delay
    }, 2000); // Adjust the delay time as needed

    return () => clearTimeout(timeout); // Cleanup the timeout
  }, []);

  const location = useLocation();

  useEffect(() => {
    // Parse the URL parameters
    const searchParams = new URLSearchParams(location.search);
    const idParam = searchParams.get("id");

    if (idParam) {
      // Scroll to the section with the specified ID
      const section = document.getElementById(idParam);
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location.search]);

  return (
    <>


<Helmet>
    <title> Elevate Your Brand with a Full-Service Branding Agency.</title>
        <meta name="description" content="- Discover how our full-service branding agency can transform your brand's identity and visibility. Let's boost your brand together." />
        <link rel="canonical" href="https://www.example.com"/>

        <meta name="keywords" content="HTML, CSS, JavaScript"/>

      </Helmet>



      {/* Hero Section */}
      <section className="hero-section">
        <div className="banner-hero-about">
          <Nav />
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-10">
                <div className="aboutdivcontent">
                  <h2>
                    Katsam Media:<br></br>A Full-Service Agency Conglomerate
                  </h2>
                  <p>
                    Your Complete Branding & Marketing Partner,<br></br>
                    Where logic meets creativity
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div class="gradient-container">
        <div class="conic-gradient"></div>
      </div>

      <div
        className={`gradient-container ${
          isTransitioning ? "transitioning" : ""
        }`}
      >
        {/* Content here */}
      </div>

      {/* Full service agency */}
      <section className="aboutus-desc" id="aboutus-desc">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-8 col-12 p-0  align-self-center">
              <div className="Km-des-about">
                <h5>
                  Full-Service Agency Conglomerate:
                  <br></br> A series & network of Agencies
                </h5>
                <span className="strip2"></span>
                <div className="KM-desc2-about">
                  <h6>
                    Experience the pinnacle of marketing excellence with Katsam
                    Media agencies, where{" "}
                    <span className="km-bold">
                      Complete Branding is not just a promise, but a passion.
                    </span>
                  </h6>
                  <p>
                    Katsam Media stands as a pinnacle in{" "}
                    <span className="km-bold">full-service branding,</span>{" "}
                    housing a{" "}
                    <span className="km-bold">
                      network of specialized agencies
                    </span>{" "}
                    for each facet of marketing. From crafting captivating brand
                    strategies to designing compelling visuals, our{" "}
                    <span className="km-bold">diverse agencies</span> cater to
                    every aspect of brand enhancement. With expertise in{" "}
                    <span className="km-bold">
                      digital marketing, public relations, market research, and
                      content creation,
                    </span>{" "}
                    each agency within our umbrella delivers{" "}
                    <span className="km-bold">tailored solutions.</span> By
                    overseeing <span className="km-bold">brand management</span>{" "}
                    and ensuring consistency across touchpoints, Katsam Media
                    ensures{" "}
                    <span className="km-bold">
                      holistic brand success, empowering businesses
                    </span>{" "}
                    to thrive in the competitive market landscape.
                  </p>
                </div>
              </div>
            </div>
            {/* <div className="col-md-1"></div> */}
            <div className="col-md-4 col-12 align-self-center">
              <div className="aboutleftimg">
                {/* <IoPlay className="about-play-button"/> */}
                <video

autoplay="autoplay"
loop=""
muted="muted"
webkit-playsinline=""
controls="true"
class="video"
id="theplayer"
type="video/mp4"
width="100%"
height="auto"
>
<source src={video} type="video/mp4" width="100%" />
</video>                {/* <div className="card-details-about">
                <h5>What is Full Service Agency?</h5>
              </div>
              <div className='caption-about'>
              </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Understanding Katsam */}
      <section className="Km-about-me" id="about-un">
        <div className="container-fluid">
          <div className="row">
            <div className="about-me-capt">
              <h3 className="title">
                Katsam Media Purpose: Transforming Indian Brands, Empowering
                Global Success with full-service agency networks.
              </h3>
              <span className="strip2"></span>

              <p>
                Katsam Media, a full-service agency conglomerate, is dedicated
                to <span className="km-bold">transforming Indian brands,</span>{" "}
                empowering them for{" "}
                <span className="km-bold">global success</span> through{" "}
                <span className="km-bold">
                  logical & innovative strategies and groundbreaking creativity
                  with series and network
                </span>{" "}
                of diverse agencies.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Boutique approach */}
      <section className="Km-mission-vision">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12] p-0">
              <div className="blacksectionabout blacksection">
                <div className="row">
                  <div className="col-md-12 col-12">
                    <div className="Km-des-about">
                      <h5>A Boutique Approach for Indian Brands</h5>
                      <span className="strip2"></span>
                      <div className="KM-desc2-about1">
                        <p>
                          <span className="km-bold">
                            Transforming Indian Brands:
                          </span>
                          <br></br>
                          At Katsam Media, we’re driven by a fervent commitment
                          to revolutionize Indian brands, breathing new life
                          into their identities and narratives. Our passion lies
                          in uncovering the essence of each brand, crafting
                          innovative strategies, and fostering authentic
                          connections with audiences. Through meticulous
                          attention to detail and unwavering dedication, we
                          empower Indian brands to rise above the ordinary and
                          claim their rightful place as leaders in their
                          industries.
                        </p>
                        <p>
                          <span className="km-bold">
                            Empowering Global Success:
                          </span>
                          <br></br>
                          Our mission at Katsam Media extends far beyond
                          borders, aiming to propel Indian brands onto the
                          global stage with resounding success. We believe in
                          the power of storytelling, innovation, and strategic
                          vision to transcend geographical boundaries and
                          resonate with audiences worldwide. By empowering
                          Indian brands to embrace their uniqueness and leverage
                          their strengths, we pave the way for unparalleled
                          success on a global scale.
                        </p>
                        <p>
                          <span className="km-bold">
                            With Full-Service Agency Networks:
                          </span>
                          <br></br>
                          At Katsam Media, we understand that true
                          transformation requires a comprehensive approach.
                          That’s why we offer full-service agency networks,
                          providing our clients with a diverse range of
                          expertise and resources to meet their every need. From
                          brand strategy and creative content to digital
                          marketing and beyond, our integrated services ensure
                          that every aspect of your brand’s journey is
                          meticulously curated and optimized for success. Join
                          us as we embark on a transformative journey together,
                          empowering Indian brands to conquer the global market.
                        </p>
                      </div>
                    </div>
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="Km-about-me bg-white">
        <div className="row">
          <div class="Km-des-about">
            <h5>
              From Our CEO: Empowering Indian Startups with Katsam Media’s
              Expertise in Cultivating Micro-Communities for Brand Prominence{" "}
            </h5>
            <span class="strip2"></span>
            <div class="KM-desc2-about"></div>
          </div>
         

          
            <div className="col-md-7 col-sm-12 ">
              <div className="row">
                <div class="Founder pr-2">
                  <p className="mt-3">
                  In the whirlwind of startup ventures, I’ve witnessed the birth of countless ideas. Amidst this chaos, Katsam Media shines as a beacon of expertise. With an intimate grasp of Indian consumer behavior, we understand the dreams of founders striving to make their mark. Despite the crowded market, I remain steadfast in our belief in success. India’s expansive consumer market holds endless possibilities, and we excel in capitalizing on them. Crafting micro-communities where brands connect deeply with their audience is our forte. Through compelling stories, we instill a sense of belonging and purpose. We are deeply committed to the success of Indian brands.
</p>

<p>I believe that a full-service agency conglomerate holds profound importance. It embodies convenience and a heartfelt commitment to support brands in every aspect of their journey. In a landscape as diverse as India, a one-stop solution ensures that brands receive the attention, care, and tailored strategies they deserve. It’s not just about efficiency; it’s about nurturing a sense of belonging and partnership, where every triumph feels like a shared victory.
                  </p>

                  <i class="fa fa-quote-right" aria-hidden="true"></i>
                  <br></br>
                  <h5>Keshav Seksaria</h5>
                  <h6>
                    <TypeIt
                      options={{
                        strings: [
                          "CEO | Katsam <span className='red'>M</span>edia",
                        ],
                        speed: 10,
                        waitUntilVisible: true,
                      }}
                    />
                  </h6>
                </div>
              </div>
            </div>

            <div className="col-md-5 col-sm-12 align-self-center ">
              <div class="aboutleftimg">
                <img src={km} class="img-fluid founderimg" alt="full service advertising agency, full service brand agency " />
              </div>
            </div>
         
        </div>
      </section>

      <Cta />
      <Footer />
    </>
  );
}
