import React, {useEffect, useState} from "react";
import Nav from "../Navbar.js/Nav";
import { Link } from "react-router-dom";
import caseimg from '../assests/1.png'
import caseimg2 from '../assests/2.png'

import Cta from "../Include/Cta";
import Footer from "../Include/Footer";

const Blog = () => {


  const dynamicData = [
    {
      id: 1,
      src: caseimg,
      description: "Latest Marketing Tips 2024: How's the Vatavaran?",
      desc:"Would you be interested in knowing what's up in the professional marketing circles and firms who make it big, or are you catching up from the past year .",
    },
    {
      id: 2,
      src: caseimg2,
      description: "Need of the Decade: Full Service Agency Conglomerate",
      desc:"Need of the Decade: Full Service Agency Conglomerate If you're wondering what is a full-service agency conglomerate then read through! And if you feel stuck in deciding whether .."
    }
    // Add more items as needed
    // Add more items as needed
  ];



  const [teamData, setTeamData] = useState();
  //(teamData);

  const queryParameters = new URLSearchParams(window.location.search)
  const name = queryParameters.get("name")

  useEffect(() => {
    const fetchTeamData = async () => {
      try {
        const response = await fetch(
          "https://wondar-dev.katsamsoft.com/api/resource/KM%20Blog?fields=[%22*%22]"
        );
        
        const data = await response.json();
         
        setTeamData(data.data);

      } catch (error) {
        console.error("Error fetching team data:", error);
      }
    };

    fetchTeamData();
  }, []);
 
  return (
    <>
      <section className="hero-section">
        <div className="banner-hero-about">
          <Nav />
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-8">
              
              <div className='aboutdivcontent'>
                <h2>
                  {" "}
                  Katsam Media Research Gateway
                </h2>
                <p>
                Enter Katsam Intellect: Opening Doors to Profound Research, Enabling Informed Decision-Making and Innovation.
                </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

   

   
      <section  className='bloggrid' >
    <div className='container'>
        <div className='row'>
         

        {dynamicData.map((item) => (
                <>
            <div className='col-md-6 col-12'>
                <div className='imgblog'>
                    <img src={item.src}  className='img-fluid'/>
            <div className='blogtitle'>
                <p> {item.description}</p>
                <span className='date'>22 April, 2024</span>&nbsp;&nbsp;&nbsp;

            </div>
            <div className='blogdivider'></div>
            <div className='readmorearea'>
              <div className='row'>
                <div className='col-md-1 col-2'>
<svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="17.5" cy="17.5" r="17" stroke="black"/>
<path d="M8 16.5089L23.7534 16.5089L18.0271 13" stroke="black" stroke-width="1.5"/>
<path d="M8 19.5L26.3129 19.5" stroke="black"/>
</svg>
</div>
                <div className='col-4 align-self-center'><  Link to="/blogdetails"><span  className='readmore'>Read More</span> </Link></div>

              </div>
            </div>
                </div>
            </div>

            </>
              ))}
        </div>

       
    </div>
</section>


      <Cta />
      <Footer />
    </>
  );
};

export default Blog;
