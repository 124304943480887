import React, { useState, useEffect } from "react";
import OwlCarousel from "react-owl-carousel";
import { IoPlay } from "react-icons/io5";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const Media = () => {
  const [reelsData, setReelsData] = useState([]);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchReelsData();
  }, []);

  const fetchReelsData = async () => {
    try {
      const response = await fetch(
        "https://wondar-dev.katsamsoft.com/api/resource/KM%20Reels?fields=[%22*%22]"
      );
      const data = await response.json();
      setReelsData(data.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching reels data:", error);
      setLoading(false);
    }
  };

  const openModal = (videoUrl) => {
    setSelectedVideo(videoUrl);
  };

  const closeModal = () => {
    setSelectedVideo(null);
  };

      const options = {
        items: 5,
        loop: false,
        nav: false,
        dots: false,
        margin: 25,
        autoplay: true,
        autoplayTimeout: 2000,
        autoplayHoverPause: true,
        responsive: {
          0: {
            items: 1.5,
          },
          600: {
            items: 1.5,
          },
          1000: {
            items: 3.5,
          },
          1200: {
            items: 4.5,
          },
        },
      };
  return (
    <>
    <section>
<div className="menu d-none d-sm-none d-md-block">
        <div className="menu__item">
           <div className="marquee">
             {/* <div className="marquee__inner">
               <span className="d-block d-sm-none d-md-block">Media Reels</span>
               <span className="d-block d-sm-none d-md-block">Media Reels</span>
               <span className="d-block d-sm-none d-md-block">Media Reels</span>
               <span>Media Reels</span>
             </div> */}
           </div>
         </div>
         </div>

      <div className="container-fluid">
      

        {loading ? (
          <div>Loading...</div>
        ) : (
          <OwlCarousel className="owl-theme" {...options}>
            {reelsData.map((item, index) => (
              <div key={index} className="item">
                <IoPlay
                  className="media-play-button"
                  onClick={() => openModal('https://wondar-dev.katsamsoft.com/' + item.reel_video)}
                />
                <img
                  src={"https://wondar-dev.katsamsoft.com/" + item.reel_poster}
                  alt={item.custom_reel_title}
                />
                <div className="Media-reel-box">
                  <h4>{item.custom_reel_title}</h4>
                </div>
              </div>
            ))}
          </OwlCarousel>
        )}

{selectedVideo && (
  <div>
    {/* Black overlay background */}
    <div className="modal-backdrop fade show" style={{ backgroundColor: 'rgba(0, 0, 0, 1)' }}></div>

    <div className="show" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
      <div className="modal fade show d-block" tabIndex="-1" role="dialog">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={closeModal}
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <div className="modal-body">
              {/* Your modal body content */}
              <video src={selectedVideo} autoPlay controls />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)}




      </div>
    </section>










</>


  );
};

export default Media;
