import React, { useState, useEffect } from "react";
import logo from "../whitelogo.png";
import {Link }from "react-router-dom";

export default function Footer() {
  const [isBouncing, setIsBouncing] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const windowHeight = window.innerHeight;
      const documentHeight = document.body.clientHeight;
      const scrollPercentage =
        (scrollPosition / (documentHeight - windowHeight)) * 100;

      if (scrollPercentage >= 90 && !isBouncing) {
        setIsBouncing(true);
        setTimeout(() => {
          setIsBouncing(false);
        }, 1000); // Adjust this value as per your animation duration
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isBouncing]);

  return (
    <footer id="footer1">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-11 col-sm-12">
            <div className="footer-space">
              <div className="row">
              <div className="col-md-5 col-sm-12 d-md-none d-sm-block">
                  <div className="footerleft">
                    <img src={logo} width={180} className="img-fluid mx-auto" alt=""/>
                  </div>
                </div>
                <div className="col-md-5 col-sm-12 d-flex">
                  <div className="footerleft1">
                    <h5 className="blinking">Where Logic meets Creativity </h5>
                    <p className="align-items-start border-none mt-0">Ready to transform your brand?</p>
                  </div>
                </div>
                <div className="col-md-7 col-sm-12 align-self-center">
                  <div className="footerright">
                    <h5>Explore Katsam Media.</h5>
                    <ul className="d-flex flex-column flex-md-row ">
                      <Link className=" text-decoration-none " to="/about">
                      <li className="link">About</li>
                      </Link>
                     

                      <Link className="text-decoration-none " >
                      <li className="link">Katsam Experience</li>
                      </Link>

                      <Link className="text-decoration-none " to="/casestudies">
                      <li className="link">Katsam Intellect</li>
                      </Link>
                      <Link className="text-decoration-none" to="/blog">
                      <li className="link">
Career & People</li>
                      </Link>
                      <Link className="text-decoration-none" to="/contact">
                      <li className="link">Contact</li>
                      </Link>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="footer-space">
              <div className="row">
                <div className="col-md-5 col-sm-12 d-md-block d-sm-none">
                  <div className="footerleft">
                    <img src={logo} width={180} className="img-fluid mx-auto" />
                  </div>
                </div>
                <div className="col-md-7 col-sm-12 align-self-center">
                  <div className="footerright">
                    <h5>Our Agencies.</h5>
                    <ul className="d-flex flex-column flex-md-row">
                      <Link to="/?id=kalakaari"><li className="link">Kalakaari Studios</li></Link>
                      <Link to="/?id=vibeee"><li className="link">Vibeee Production</li></Link>
                      <Link to="/?id=rangeen"><li className="link">Rangeen Networks</li></Link>

                      
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className="footer-space">
              <div className="row d-flex flex-column-reverse flex-md-row">
                <div className="col-md-5 col-sm-12">
                  <div className="footerleft border-sm-top border-md-none">
                    <p>&copy; Copyright 2024 | All Rights Reserved </p>
                  </div>
                </div>
                <div className="col-7 align-self-md-center">
                  <div className="footerright">
                    <ul className="d-flex">
                      <Link to="https://www.facebook.com/katsammedia/">
                      <li className="link">
                       Facebook
                      </li>
                      </Link>
                      <Link to="https://www.instagram.com/katsammedia/">
                      <li  className="link">
                       Instagram
                      </li>
                      </Link>
                      <Link to="https://www.linkedin.com/company/katsam-media/">
                      <li  className="link">
                       Linkedin
                      </li>
                      </Link>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-1 position-relative">
          <div className="footertopcircle d-sm-none d-md-block">
                <div className="footercircle"></div>
              </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
