import React, { useEffect } from "react";
import gsap from "gsap";
import Nav from "../Navbar.js/Nav";
import shape1 from "../assests/shape1.png";
import arrownew from "../assests/arrownew.png";
import { Link, NavLink } from "react-router-dom";

export default function Hero() {
  useEffect(() => {
    const animateHero = () => {
      const elementsToFadeIn = [
        ".banner-hero h2",
        ".banner-hero h3",
        ".banner-hero h6",
        ".btnarea",
      ];

      gsap.fromTo(
        elementsToFadeIn,
        { opacity: 0, y: 50 },
        {
          opacity: 1,
          y: 0,
          duration: 1,
          delay: 0.5,
          stagger: 0.2,
        }
      );

      // SVG continuous left-right motion
      const arrow = document.getElementById("arrow");
      gsap.fromTo(
        arrow,
        { x: "-5%" },
        {
          x: "10%",
          duration: 1,
          ease: "power1.inOut",
          repeat: -1,
          yoyo: true,
        }
      );
    };

    animateHero();
  }, []);

  return (
    <>
      <main className="hero-section">
        <div className="banner-hero">
          <Nav />
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6 col-2">
                <div className="bannerleft">
                  <div className="bannercaption">
                    <h2>BHARAT’S OWN FULL SERVICE </h2>
                    <h3>Branding, Advertising & Marketing Agency</h3>
                    <h6>
                      A Boutique Approach To <b>Brand Transformation</b>
                    </h6>

                    <ul className="bannerul">
                      <li>
                        <img src={arrownew} className="img-fluid" alt="Full Service Marketing Agency | Social Media | PPC | Influencer - India" />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <Link to="/about?id=aboutus-desc" className="">
                          <span>
                            Understand Full-Service Agency Conglomerate.
                          </span>
                        </Link>
                      </li>
                      <li>
                        <img src={arrownew} className="img-fluid" alt="Full Service Marketing Agency | Social Media | PPC | Influencer - India" />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <Link to="/about?id=about-un" className="">
                          <span>Understand Katsam Media Purpose.</span>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-10 p-0">
                <img
                  src={shape1}
                  className="img-fluid bannerimgleft"
                  alt="Full Service Marketing Agency | Social Media | PPC | Influencer - India"
                />
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
