import React from "react";
import arrow from './M.png'
import Loader from '../Pre.gif'
export default function Cta() {
  return (
    <section id="cta">
      <div className="container-fluid">
        <div className="cta-section">
          <div className="row">
            <div className="col-md-7 col-sm-12">
              <div className="cta-capt">
                <div className="headcontainercta">
                  <div class="redcta"></div>
                  <h5 className="headtitlecta">
                  Explore Wonders of Full-Service Agency Conglomerate<br></br>
                  Let us Audit your Brand, Today!<br></br>
                  
                  </h5>
                </div>

                <button className="ctabtn d-flex" data-toggle="modal" data-target=".bd-example-modal-lg">Let's Talk   &nbsp; 
                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="9" viewBox="0 0 22 9" fill="none">
                  <path d="M0.122192 8.35693L20.6191 7.99916" stroke="white">
                  </path>
                  <path d="M0.0680279 5.25522L17.5654 4.94981L11.1372 1.16351" stroke="white" stroke-width="2">
                  </path>
                </svg>
                </button>

              </div>
            </div>
            <div className="col-5">
              <img
                src={arrow}
                className="img-fluid arrowfoter d-none d-sm-none d-md-block"
                alt=""
              />
              
            </div>
          </div>
        </div>
      </div>


      <div class="modal fade bd-example-modal-lg" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg modal-dialog-centered">
                <div class="modal-content ctabtnpopup">

                <div class="modal-body p-0">
                   <div className='container'>
                        <div className='row'>
                            <div className='col-6 red-div'>
                                <img src={Loader} alt=''/>
                                <div className='red-div-head'>
                                    <h1>Katsam Media</h1>
                                    <p>lorem of the is the and the end value has mixed support, end value has mixed support, consider using flex-end instead</p>
                                </div>
                            </div>
                            <div className="col-6 p-20 black-div">
                               <div className='row'>
                               <button type="button closectabtn" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button> 
                                    <h1>Contact Form</h1>
                             

                                    <div class="col-6">
                                        <input type='text' placeholder='Name' />
                                    </div>
                                    <div class="col-6">
                                        <input type='text' placeholder='Agency' />
                                    </div>
                                    <div class="col-6">
                                        <input type='text' placeholder='Email' />
                                    </div>
                                    <div class="col-6">
                                        <input type='text' placeholder='Contact' />
                                    </div>
                                    <div className='col-12'>
                                        <textarea placeholder='Message' className='p-0 pt-2 pb-4'></textarea>
                                    </div>
                                    </div>
                                <div>
                                    <button className="ctabtn d-flex" data-toggle="modal" data-target=".bd-example-modal-lg">Submit Now &nbsp; <svg xmlns="http://www.w3.org/2000/svg" width="22" height="9" viewBox="0 0 22 9" fill="none"><path d="M0.122192 8.35693L20.6191 7.99916" stroke="white"></path><path d="M0.0680279 5.25522L17.5654 4.94981L11.1372 1.16351" stroke="white" stroke-width="2"></path></svg></button>

                                </div>
                            </div>
                        </div>
                   </div>
                </div>



            </div>
            </div>
            </div>

    </section>



  );
}
