import React, { useEffect, useState } from "react";
import Nav from "./Navbar.js/Nav";
import Team from "../src/About/Team";
import Cta from "../src/Include/Cta";
import Footer from "../src/Include/Footer";
import Media from "../src/Home/Media";
import { useDropzone } from "react-dropzone";
import { Link } from "react-router-dom";
import video from "../src/video.mp4";
import { Helmet } from 'react-helmet';

export default function Career() {
  const [loading, setLoading] = useState(true);

  const [formData, setFormData] = useState({
    full_name: "",
    email: "",
    contact_number: "",
    city: "",
    skill: "",
    linkedin_profile: "",
    resume_base64: "", // New field to store base64 encoded resume
  });

  const [jobData, setJobData] = useState([]);
  //(jobData, "fd");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://wondar-dev.katsamsoft.com/api/resource/KM%20Career?fields=[%22*%22]"
        );
        const data = await response.json();

        // Check if the data is an array before updating the state
        setJobData(data.data);
        console.error("Invalid data format:", data);

        setLoading(false); // Set loading to false after data is fetched
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false); // Set loading to false in case of an error
      }
    };

    fetchData();
  }, []);

  //(formData, "dfwdsfsd");
  const [selectedFile, setSelectedFile] = useState(null);

  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFormData({ ...formData, resume_base64: reader.result });
      };
      reader.readAsDataURL(file);
    }
  };

  const onDrop = (acceptedFiles) => {
    setSelectedFile(acceptedFiles[0]);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  //(getRootProps);
  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const formDataToSubmit = new FormData();
      formDataToSubmit.append("full_name", formData.full_name);
      formDataToSubmit.append("email", formData.email);
      formDataToSubmit.append("contact_number", formData.contact_number);
      formDataToSubmit.append("city", formData.city);
      formDataToSubmit.append("skill", formData.skill);
      formDataToSubmit.append("linkedin_profile", formData.linkedin_profile);
      if (selectedFile) {
        formDataToSubmit.append("resume", formData.resume_base64);
      }

      const response = await fetch(
        "https://wondar-dev.katsamsoft.com/api/resource/KM Resume",
        {
          method: "POST",
          headers: {
            // No need to set Content-Type manually when using FormData
            Cookie:
              "full_name=Guest; sid=Guest; system_user=no; user_id=Guest; user_image=", // Add your cookie header here
          },
          body: formDataToSubmit,
        }
      );

      //(formDataToSubmit, "dss");
      //(response, "AKASH");

      if (response.ok) {
        //(response, "AKASH");
        //("Data submitted successfully");
      } else {
        console.error("Error submitting data");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  return (
    <>
    <Helmet>
    <title>Career</title>
        <meta name="description" content="- Discover how our full-service branding agency can transform your brand's identity and visibility. Let's boost your brand together." />
      </Helmet> 
      <section className="hero-section">
        <div className="banner-hero-about">
          <Nav />
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-8">
                <div className="aboutdivcontent">
                  <h2>
                    Culture-Driven<br></br> Career Growth Opportunities.
                  </h2>
                  <p>
                    Shape your future through impactful work in Katsam Media’s
                    vibrant culture, where careers are defined by meaningful
                    contributions and collaborative excellence.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div class="menu d-none d-sm-none d-md-block">
          <div class="menu__item">
            <div class="marquee">
              <div class="marquee__inner">
                <span className="d-block d-sm-none d-md-block">
                  kATSAM MEDIA
                </span>
                <span className="d-block d-sm-none d-md-block">
                  kATSAM MEDIA
                </span>
                <span className="d-block d-sm-none d-md-block">
                  kATSAM MEDIA
                </span>

                <span>kATSAM MEDIA</span>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid">
          <div className="headcontainer">
            <div class="red-circle-apply"></div>
            <h5 className="headtitle">
              Career Opportunities
              <div className="strip"></div>
            </h5>
          </div>
        </div>
      </section>

      <section className="careercard">
        <div className="container-fluid">
          <div className="row">
            {loading && <p>Loading...</p>}

            {!loading &&
              Array.isArray(jobData) &&
              jobData.map((job, index) => (
                <div key={index} className="col-md-4 col-lg-4 col-12 col-xl-4">
                  <div className="jobbox">
                    <div className="jbdetaiil">
                      <h3>{job.custom_job_title}</h3>
                      <p className="jobboxexp">
                        Exp: {job.custom_job_experience_not_user_year} year |
                        Indore (M.P) {job.date}
                      </p>
                      <div className="jobdivider"></div>
                      <p>{job.custom_job_short_description}</p>
                      <div className="jobdivider"></div>
                      <div className="row">
                        <div className="col-5 align-self-center">
                          <div className="aplynow1"></div>
                          <div className="jobtype"></div>
                        </div>
                        <div className="col-12">
                          <div className="aplynow">
                            <button
                              data-toggle="modal"
                              data-target={`#jobModal${index}`}
                            >
                              Apply Now
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Modal */}
                  <div
                    className={`modal fade bd-example-modal-lg1`}
                    id={`jobModal${index}`}
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="myLargeModalLabel"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog modal-xl modal-dialog-centered">
                      <div className="modal-content">
                        <div className="modal-body p-0 ctabtnpopup">
                          <div className="container">
                            <div className="row">
                              <div className="col-md-6 col-sm-12 bg-white bg-white p-20 align-self-center">
                                <div className="resumeleftcont">
                                  <h4>{job.custom_job_title}</h4>
                                  <span className="exp">
                                    Exp:{" "}
                                    {job.custom_job_experience_not_user_year}{" "}
                                    year | Indore (M.P)
                                  </span>
                                  <div className="jobdesc">
                                    <h5>Job Description :</h5>
                                    <ul
                                      className="jobdetail"
                                      dangerouslySetInnerHTML={{
                                        __html: job.custom_job_brief,
                                      }}
                                    ></ul>
                                  </div>
                                </div>
                                {/* ... Your left content */}
                              </div>
                              <div className="col-md-6 col-sm-12 p-20 black-div align-self-center">
                                <div className="row">
                                  <button
                                    type="button closectabtn"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                  >
                                    <span aria-hidden="true">&times;</span>
                                    &nbsp;
                                  </button>
                                  <h1>Profile Information</h1>
                                  <form onSubmit={handleSubmit}>
                                    <div className="row">
                                      <div className="col-md-6 col-sm-6">
                                        <input
                                          type="text"
                                          name="full_name"
                                          value={formData.full_name}
                                          onChange={handleChange}
                                          placeholder="Full Name *"
                                          required
                                        />
                                      </div>
                                      <div className="col-md-6 col-sm-6">
                                        <input
                                          type="tel"
                                          name="contact_number"
                                          minLength={10}
                                          maxLength={10}
                                          value={formData.contact_number}
                                          onChange={handleChange}
                                          placeholder="Contact Number *"
                                          required
                                        />
                                      </div>
                                      <div className="col-md-6 col-sm-6">
                                        <input
                                          type="text"
                                          name="email"
                                          value={formData.email}
                                          onChange={handleChange}
                                          placeholder="Email ID *"
                                          required
                                        />
                                      </div>
                                      <div className="col-md-6 col-sm-6">
                                        <input
                                          type="text"
                                          name="city"
                                          value={formData.city}
                                          onChange={handleChange}
                                          placeholder="City *"
                                          required
                                        />
                                      </div>
                                      {/* <div className="col-md-12 col-sm-12">
          <textarea
            type="text"
            placeholder="Top 5 Skills"
            className="p-0 pt-2 pb-2"
            name="skill"
            value={formData.skill}
            onChange={handleChange}
          ></textarea>
        </div> */}
                                      <div className="col-md-12 col-sm-6">
                                        <input
                                          type="text"
                                          name="linkedin_profile"
                                          value={formData.linkedin_profile}
                                          onChange={handleChange}
                                          placeholder="LinkedIn Profile"
                                        />
                                      </div>
                                      <div className="col-12 resume-btn">
                                        <input
                                          className=""
                                          type="file"
                                          accept=".pdf,.doc,.docx"
                                          onChange={handleFileChange}
                                        />
                                        {/* <p className="text-white">Upload Resume Here *</p>
          <div {...getRootProps()} className="upload-Resume">
          <input {...getInputProps()} />
          {isDragActive ? (
            <p>Drop the file here ...</p>
          ) : (
            <p>Drag 'n' drop a file here, or click to select a file</p>
          )}
        </div>
        {selectedFile && <p>Uploaded File: {selectedFile.name}</p>} */}
                                      </div>
                                    </div>
                                    <button
                                      type="submit"
                                      className="ctabtn d-flex"
                                    >
                                      Apply Now &nbsp;
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="22"
                                        height="9"
                                        viewBox="0 0 22 9"
                                        fill="none"
                                      >
                                        <path
                                          d="M0.122192 8.35693L20.6191 7.99916"
                                          stroke="white"
                                        ></path>
                                        <path
                                          d="M0.0680279 5.25522L17.5654 4.94981L11.1372 1.16351"
                                          stroke="white"
                                          strokeWidth="2"
                                        ></path>
                                      </svg>
                                    </button>
                                  </form>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </section>

      <div
        class="modal fade bd-example-modal-lg1"
        tabindex="-1"
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-xl modal-dialog-centered">
          <div class="modal-content">
            {/* <button type="button" class="close" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
        </button> */}

            <div class="modal-body p-0 ctabtnpopup">
              <div className="container">
                <div className="row">
                  <div className="col-md-6 col-sm-12 bg-white bg-white p-20 align-self-center">
                    <div className="resumeleftcont">
                      <h4>Graphic Designer</h4>
                      <span className="exp">Exp: 3-5 year | Indore (M.P)</span>
                      <div className="jobdesc">
                        <h5>Job Description :</h5>
                        <ul className="jobdetail">
                          <li>
                            Gather and evaluate user requirements in
                            collaboration with product managers and engineers
                          </li>
                          <li>
                            Gather and evaluate user requirements in
                            collaboration with product managers and engineers
                          </li>
                          <li>
                            Illustrate design ideas using storyboards, process
                            flows, and sitemaps
                          </li>
                          <li>
                            Design graphic user interface elements, like menus,
                            tabs, and widgets
                          </li>
                          <li>
                            Build page navigation buttons and search fields
                          </li>
                          <li>
                            Develop UI mockups and prototypes that clearly
                            illustrate how sites function and look like
                          </li>
                          <li>
                            Create original graphic designs (e.g. images,
                            sketches, and tables)
                          </li>
                          <li>
                            Prepare and present rough drafts to internal teams
                            and key stakeholders
                          </li>
                          <li>
                            Identify and troubleshoot UX problems (e.g.
                            responsiveness)
                          </li>
                          <li>
                            Conduct layout adjustments based on user feedback
                          </li>
                          <li>
                            Adhere to style standards on fonts, colors, and
                            images
                          </li>
                          <li>Convert HTML code</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12 p-20 black-div">
                    <div className="row">
                      <button
                        type="button closectabtn"
                        class="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                           
                      </button>
                       <h1>Apply Now!</h1>(
                      <form onSubmit={handleSubmit}>
                        <div className="row">
                          <div className="col-md-4 col-sm-6">
                            <input
                              type="text"
                              name="full_name"
                              value={formData.full_name}
                              onChange={handleChange}
                              placeholder="Name"
                            />
                          </div>
                          <div className="col-md-4 col-sm-6">
                            <input
                              type="text"
                              name="contact_number"
                              value={formData.contact_number}
                              onChange={handleChange}
                              placeholder="Number"
                            />
                          </div>
                          <div className="col-md-4 col-sm-6">
                            <input
                              type="text"
                              name="email"
                              value={formData.email}
                              onChange={handleChange}
                              placeholder="Email"
                            />
                          </div>
                          <div className="col-md-4 col-sm-6">
                            <input
                              type="text"
                              name="city"
                              value={formData.city}
                              onChange={handleChange}
                              placeholder="City"
                            />
                          </div>
                          <div className="col-md-8 col-sm-12">
                            <textarea
                              type="text"
                              placeholder="Top 5 Skills"
                              className="p-0 pt-2 pb-2"
                              name="skill"
                              value={formData.skill}
                              onChange={handleChange}
                            ></textarea>
                          </div>
                          <div className="col-md-12 col-sm-6">
                            <input
                              type="text"
                              name="linkedin_profile"
                              value={formData.linkedin_profile}
                              onChange={handleChange}
                              placeholder="LinkedIn Profile"
                            />
                          </div>
                          <div className="col-12">
                            <input
                              type="file"
                              accept=".pdf,.doc,.docx"
                              onChange={handleFileChange}
                            />
                            {/* <p className="text-white">Upload Resume Here *</p>
          <div {...getRootProps()} className="upload-Resume">
          <input {...getInputProps()} />
          {isDragActive ? (
            <p>Drop the file here ...</p>
          ) : (
            <p>Drag 'n' drop a file here, or click to select a file</p>
          )}
        </div>
        {selectedFile && <p>Uploaded File: {selectedFile.name}</p>} */}
                          </div>
                        </div>

                        <button type="submit" className="ctabtn d-flex">
                          Submit Now &nbsp;{" "}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="22"
                            height="9"
                            viewBox="0 0 22 9"
                            fill="none"
                          >
                            <path
                              d="M0.122192 8.35693L20.6191 7.99916"
                              stroke="white"
                            ></path>
                            <path
                              d="M0.0680279 5.25522L17.5654 4.94981L11.1372 1.16351"
                              stroke="white"
                              strokeWidth="2"
                            ></path>
                          </svg>
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <br></br>

      <section>
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-md-12 col-12 ">
              <div className="bgmate blacksection">
                <div className="row">
                  <div className="col-md-6 col-sm-12 col-lg-6 col-xl-6">
                    <div class="Km-des-about text-white">
                      <h5 className="text-white">
                        Thriving in Culture: The Katsam Media Workplace
                      </h5>
                      <span class="strip2"></span>
                      <div class="KM-desc2-about">
                        <p className="text-white">
                          At Katsam Media, our workplace is a vibrant hub where
                          culture-driven lifestyles and passion-infused work
                          cultures flourish. With modern office aesthetics
                          fostering creativity, we embrace an open-door culture,
                          encouraging collaboration and innovation. Here, every
                          individual’s creativity is celebrated, fostering a
                          dynamic and inspiring environment for all.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6 col-sm-12  col-lg-6 col-xl-6">
                    <video

                      autoplay="autoplay"
                      loop=""
                      muted="muted"
                      webkit-playsinline=""
                      controls="true"
                      class="video"
                      id="theplayer"
                      type="video/mp4"
                      width="100%"
                      height="auto"
                    >
                      <source src={video} type="video/mp4" width="100%" />
                    </video>
                  </div>

                  <br></br>
                </div>
                <div className="row">
                  <div className="instagramstrip pt-md-20 pt-sm-18">
                    <h6>
                      Watch some glimpse of <b>#LifeAtKatsam</b>
                      <Link
                        to="https://www.instagram.com/lifeatkatsam_/"
                        target="#"
                      >
                        <span className="instabtn">
                          <i class="fa fa-instagram" aria-hidden="true"></i>{" "}
                          &nbsp;&nbsp;&nbsp;Follow Us on Instagram
                        </span>
                      </Link>
                    </h6>
                  </div>
                </div>

                <Media />
              </div>
            </div>
          </div>
        </div>
      </section>

      <Team />
      <Cta />
      <Footer />
    </>
  );
}
