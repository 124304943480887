import React, { useState, useEffect } from 'react';
// import service1 from "../services.png";
import white1 from "../assests/companyimg/white1.png"
import white2 from "../assests/companyimg/white2.png"
import white3 from "../assests/companyimg/white3.png"
import { useLocation } from 'react-router-dom';
function Company() {


  
  const [isTransitioning, setTransitioning] = useState(true);

  useEffect(() => {
    // Set a timeout to update the transition after a delay
    const timeout = setTimeout(() => {
      setTransitioning(false); // Set transitioning to false after the delay
    }, 2000); // Adjust the delay time as needed

    return () => clearTimeout(timeout); // Cleanup the timeout
  }, []);

  const location = useLocation();

  useEffect(() => {
    // Parse the URL parameters
    const searchParams = new URLSearchParams(location.search);
    const idParam = searchParams.get('id');

    if (idParam) {
      // Scroll to the section with the specified ID
      const section = document.getElementById(idParam);
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location.search]);




  return (
    <>
      <section id="company-km">
        <div className="container-fluid p-0">
          <div className="col-md-12 col-12">
            <div className="blacksection">
              <div className="km-spacing-company" id="kalakaari">
                <div className="row">
                  <div className="col-md-7 col-12">
                    <div className="company-caption">
                      <h3>Kalakaari Studios</h3>
                      <span className="strip3"></span>
                      <p>
                      Thinking of a big bang of your brand in the market? Archetypes, Narratives, Personality, Sentiment is the key. Kalakaari Studio – a branding studio, a team of problem solvers; dealing in 3 niche services i.e. Brand Consulting, Brand Building, and Brand Management, specifically.
                      </p>
                      <div className="col-md-9 col-sm-12 btn-company d-flex gap-md-5 gap-3">
                        <a href="https://www.kalakaaristudios.com" target="_blank" className="link text-decoration-none text-white">www.kalakaaristudios.com</a>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-5 col-12">

                  <div className="meals-showcase">
                  <figure class="meal-photo">
        <img src={white3} className="img-fluid company-km-img" alt="Marketing Agency, full service marketing agency"  />
      </figure>
      </div>
                  </div>
                </div>
              </div>

              <div className="km-spacing-company" id='vibeee'>
                <div className="row">
                  <div className="col-md-7 col-12">
                    <div className="company-caption">
                      <h3>Vibeee Production</h3>
                      <span className="strip3"></span>
                      <p>
                      A creative content production house creating Instagram commercials, TVC, TV Commercials, Brand Audio Logos, Brand Jingles, Anthems, Digital shows, Podcast, Digital IPs & many more.! Since it’s a creative space. We believe there is no end to it. We’ll keep you hooked always.
                      </p>
                      <div className="col-md-9 col-sm-12 btn-company d-flex gap-md-5 gap-3">
                        <a href="#"  className="link text-decoration-none text-white">Website Coming Soon</a>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-5 col-12">
                  <div className="meals-showcase">
                  <figure class="meal-photo">
        <img src={white1} className="img-fluid company-km-img" alt="Marketing Agency, full service marketing agency" />
      </figure>
      </div>
                  </div>
                </div>
              </div>

              <div className="km-spacing-company" id='rangeen'>
                <div className="row">
                  <div className="col-md-7 col-12">
                    <div className="company-caption">
                      <h3>Rangeen Networks</h3>
                      <span className="strip3"></span>
                      <p>
                      To cater your needs after creative production, Identity creation, Building a Brand, i.e Outreach. Rangeen Networks fulfils the role of recall i.e. PR, Social Outreach, Influencer Marketing, talent-Influencer-creator management. All your network requirements.
                      </p>
                      <div className="col-md-9 col-sm-12 btn-company d-flex gap-md-5 gap-3">
                        <a href="#" target="_blank" className="link text-decoration-none text-white">Website Coming Soon</a>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-5 col-12">
                  <div className="meals-showcase">
                  <figure class="meal-photo">
        <img src={white2} className="img-fluid company-km-img" alt="Full Service Marketing Agency | Social Media | PPC | Influencer - India"  />
      </figure>
      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Company;
